import React from 'react';

const Footer = () => {

  return (
    <footer className="footer">
      Footer
    </footer>
  )

}

export default Footer