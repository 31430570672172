export const BLOG_API_URL = "https://api1.jmscottnovels.com/blog"
export const SECURITY_API_URL = "https://api1.jmscottnovels.com/security"
export const API_URL = "https://api1.jmscottnovels.com"
export const SIGNUP_API_URL = 'https://api1.jmscottnovels.com/signup'
// export const BLOG_API_URL = "http://localhost:8080/blog"
// export const SECURITY_API_URL = "http://localhost:8080/security"
// export const API_URL = "http://localhost:8080"
// export const SIGNUP_API_URL = 'http://localhost:8080/signup'
export const USERNAME_ATTRIBUTE_NAME = 'authenticatedUser'
export const DATE_ATTRIBUTE_NAME = 'date'
export const BLOG_MANAGER_ID = '5fc54896e862b52cc709dbe8'
export const POST_FORM_ID = '5fc66385e862b52cc709dbf0'
export const RESET_PASSWORD_URL = 'https://security.jmscottnovels.com/reset-request'